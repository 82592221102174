import * as React from 'react';
import { GiftTicketPurchaseComponent } from '../../components/gift-ticket-purchase/gift-ticket-purchase.component';

export class GiftTicketPurchaseScreen extends React.Component<any, {}> {

    public render() {
        return <div className={'container py-8'}>
            <GiftTicketPurchaseComponent/>
        </div>;
    }
}
