import { Repository } from '@codebuild/cookie-jar/libs/repository';
import { SyncStorage } from '@codebuild/cookie-jar/libs/sync.storage';
import moment from 'moment';
import { AnyAction } from 'redux';
import { ThunkAction } from 'redux-thunk';

export abstract class UserActions {
    public static CURRENT_PRODUCTION_KEY = 'CurrentProduction';
    public static RESULT_REQUEST_KEY = 'ResultRequest';
    public static ACCESS_TOKEN_KEY = 'AccessToken';
    public static ACCESS_TOKEN_KEY_EXPIRED_AT = 'AccessTokenExpiredAt';
    public static REFRESH_TOKEN_KEY = 'RefreshToken';
    public static ACCESS_TOKEN_TYPE_KEY = 'AccessTokenType';
    public static USER_KEY = 'User';
    public static CURRENCY_KEY = 'Currency';

    public static CURRENT_PRODUCTION = 'UserActions(CURRENT_PRODUCTION)';
    public static SET_USER = 'UserActions(SET_USER)';
    public static SET_CURRENCY = 'UserActions(SET_CURRENCY)';
    public static SET_RESULT_REQUEST = 'UserActions(SET_RESULT_REQUEST)';
    public static SET_ACCESS_TOKEN = 'UserActions(SET_ACCESS_TOKEN)';
    public static SET_ACCESS_TOKEN_EXPIRED_AT = 'UserActions(SET_ACCESS_TOKEN_EXPIRED_AT)';
    public static SET_REFRESH_TOKEN = 'UserActions(SET_REFRESH_TOKEN)';
    public static SET_ACCESS_TOKEN_TYPE = 'UserActions(SET_ACCESS_TOKEN_TYPE)';

    public static SET_LOGIN_REQUEST = 'UserActions(SET_LOGIN_REQUEST)';
    public static SET_REFRESH_TOKEN_REQUEST = 'UserActions(SET_REFRESH_TOKEN_REQUEST)';
    public static SET_REGISTRATION_REQUEST = 'UserActions(SET_REGISTRATION_REQUEST)';
    public static SET_WHO_AM_I_REQUEST = 'UserActions(SET_WHO_AM_I_REQUEST)';
    public static SET_FORGOT_PASSWORD_REQUEST = 'UserActions(SET_FORGOT_PASSWORD_REQUEST)';
    public static SET_FINISH_REGISTRATON_REQUEST = 'UserActions(SET_FINISH_REGISTRATION_REQUEST)';
    public static SET_RESET_PASSWORD_REQUEST = 'UserActions(SET_RESET_PASSWORD_REQUEST)';

    public static setCurrentProduction(production: any): any {
        SyncStorage.setItem(UserActions.CURRENT_PRODUCTION_KEY, production);

        return {
            type: 'neverUsed'
        };
    }

    public static setUser(user: any): AnyAction {
        SyncStorage.setItem(UserActions.USER_KEY, user || null);

        return {
            type: UserActions.SET_USER,
            payload: user
        };
    }

    public static setCurrency(currency: any): AnyAction {
        SyncStorage.setItem(UserActions.CURRENCY_KEY, currency || null);

        return {
            type: UserActions.SET_CURRENCY,
            payload: currency
        };
    }

    public static setResultRequest(requestData: any): AnyAction {
        SyncStorage.setItem(UserActions.RESULT_REQUEST_KEY, requestData || null);

        return {
            type: UserActions.SET_RESULT_REQUEST,
            payload: requestData
        };
    }

    public static setLoginRequest(data: any): AnyAction {
        return {
            type: UserActions.SET_LOGIN_REQUEST,
            payload: data
        };
    }

    public static setRefreshTokenRequest(data: any): AnyAction {
        return {
            type: UserActions.SET_REFRESH_TOKEN_REQUEST,
            payload: data
        };
    }

    public static setForgotPasswordRequest(data: any): AnyAction {
        return {
            type: UserActions.SET_FORGOT_PASSWORD_REQUEST,
            payload: data
        };
    }

    public static setFinishRegistrationRequest(data: any): AnyAction {
        return {
            type: UserActions.SET_FINISH_REGISTRATON_REQUEST,
            payload: data
        };
    }

    public static setResetPasswordRequest(data: any): AnyAction {
        return {
            type: UserActions.SET_RESET_PASSWORD_REQUEST,
            payload: data
        };
    }

    public static setRegistrationRequest(data: any): AnyAction {
        return {
            type: UserActions.SET_REGISTRATION_REQUEST,
            payload: data
        };
    }

    public static setWhoAmIRequest(data: any): AnyAction {
        return {
            type: UserActions.SET_WHO_AM_I_REQUEST,
            payload: data
        };
    }

    public static setAccessToken(accessToken: string | null): AnyAction {
        SyncStorage.setItem(UserActions.ACCESS_TOKEN_KEY, accessToken);

        return {
            type: UserActions.SET_ACCESS_TOKEN,
            payload: accessToken
        };
    }

    public static setAccessTokenExpiredAt(time: string | null): AnyAction {
        SyncStorage.setItem(UserActions.ACCESS_TOKEN_KEY_EXPIRED_AT, time);

        return {
            type: UserActions.SET_ACCESS_TOKEN_EXPIRED_AT,
            payload: time
        };
    }

    public static setRefreshToken(refreshToken: string | null): AnyAction {
        SyncStorage.setItem(UserActions.REFRESH_TOKEN_KEY, refreshToken);

        return {
            type: UserActions.SET_REFRESH_TOKEN,
            payload: refreshToken
        };
    }

    public static setAccessTokenType(accessTokenType: string | null): AnyAction {
        SyncStorage.setItem(UserActions.ACCESS_TOKEN_TYPE_KEY, accessTokenType);

        return {
            type: UserActions.SET_ACCESS_TOKEN_TYPE,
            payload: accessTokenType
        };
    }

    public static login(credentials: any, tryRedirect = false, redirectTo: string = '/'): ThunkAction<any, any, any, any> {
        return async (dispatch: (action: AnyAction) => void) => {
            try {
                dispatch(UserActions.setLoginRequest({
                    error: null,
                    response: null,
                    loading: true
                }));

                const response: any = await Repository.post('/auth/login', credentials);

                if (!response) {
                    throw new Error('something.went.wrong');
                }

                dispatch(UserActions.setAccessToken(response.token));
                dispatch(UserActions.setAccessTokenType('Bearer'));

                dispatch(UserActions.setAccessTokenExpiredAt(moment().add(response.expires_in, 'seconds').toISOString()));

                dispatch(UserActions.setLoginRequest({
                    error: null,
                    response: response,
                    loading: false
                }));

                if (tryRedirect) {
                    dispatch(UserActions.checkWhoAmIAndRedirect(redirectTo));
                }
            } catch (err) {
                dispatch(UserActions.setLoginRequest({
                    error: err,
                    response: null,
                    loading: false
                }));
            }
        };
    }

    public static renewToken(refreshToken: any): ThunkAction<any, any, any, any> {
        return async (dispatch: (action: AnyAction) => void) => {
            try {
                dispatch(UserActions.setRefreshTokenRequest({
                    error: null,
                    response: null,
                    loading: true
                }));

                const response: any = await Repository.post('/auth/refresh', {
                    refresh_token: refreshToken
                });

                if (!response) {
                    throw new Error('something.went.wrong');
                }

                dispatch(UserActions.setAccessToken(response.access_token));

                dispatch(UserActions.setAccessTokenType(response.token_type));

                dispatch(UserActions.setRefreshToken(response.refresh_token));

                dispatch(UserActions.setAccessTokenExpiredAt(moment().add(response.expires_in, 'seconds').toISOString()));

                dispatch(UserActions.setRefreshTokenRequest({
                    error: null,
                    response: response,
                    loading: false
                }));
            } catch (err) {
                dispatch(UserActions.setRefreshTokenRequest({
                    error: err,
                    response: null,
                    loading: false
                }));
            }
        };
    }

    public static forgotPassword(credentials: any): ThunkAction<any, any, any, any> {
        return async (dispatch: (action: AnyAction) => void) => {
            try {
                dispatch(UserActions.setForgotPasswordRequest({
                    error: null,
                    response: null,
                    loading: true,
                }));

                const response = await Repository.post('/auth/password/forgot', credentials);

                if (!response) {
                    throw new Error('something.went.wrong');
                }
                dispatch(UserActions.setForgotPasswordRequest({
                    error: null,
                    response: response,
                    loading: false,
                }));
            } catch (err) {
                dispatch(UserActions.setForgotPasswordRequest({
                    error: err,
                    response: null,
                    loading: false
                }));
            }
        };
    }

    public static finishRegistration(credentials: any, type?: string): ThunkAction<any, any, any, any> {
        return async (dispatch: (action: AnyAction) => void) => {
            try {
                dispatch(UserActions.setFinishRegistrationRequest({
                    error: null,
                    response: null,
                    loading: true,
                }));

                const response = await Repository.post(`/user/register-${type || 'password'}`, credentials);

                if (!response) {
                    throw new Error('something.went.wrong');
                }

                dispatch(UserActions.setFinishRegistrationRequest({
                    error: null,
                    response: response,
                    loading: false,
                }));
            } catch (err) {
                dispatch(UserActions.setFinishRegistrationRequest({
                    error: err,
                    response: null,
                    loading: false
                }));
            }
        };
    }

    public static resetPassword(credentials: any): ThunkAction<any, any, any, any> {
        return async (dispatch: (action: AnyAction) => void) => {
            try {
                dispatch(UserActions.setResetPasswordRequest({
                    error: null,
                    response: null,
                    loading: true,
                }));

                const response = await Repository.post('/auth/password/reset', credentials);

                if (!response) {
                    throw new Error('something.went.wrong');
                }

                dispatch(UserActions.setResetPasswordRequest({
                    error: null,
                    response: response,
                    loading: false,
                }));
            } catch (err) {
                dispatch(UserActions.setResetPasswordRequest({
                    error: err,
                    response: null,
                    loading: false
                }));
            }
        };
    }

    public static logout(): any {
        return async (dispatch: (action: AnyAction) => void) => {
            dispatch(UserActions.setUser(null));

            dispatch(UserActions.setAccessToken(null));

            dispatch(UserActions.setRefreshToken(null));

            dispatch(UserActions.setAccessTokenExpiredAt(null));

            dispatch(UserActions.setAccessTokenType(null));

            dispatch(UserActions.setCurrentProduction(null));

            dispatch(UserActions.setLoginRequest({
                error: null,
                response: null,
                loading: false
            }));

            dispatch(UserActions.setWhoAmIRequest({
                error: null,
                response: null,
                loading: false
            }));
            window.location.href = '/';
        };
    }

    public static checkWhoAmI(): any {
        return async (dispatch: (action: AnyAction) => void) => {
            try {
                dispatch(UserActions.setWhoAmIRequest({
                    error: null,
                    response: null,
                    loading: true
                }));

                const response = await Repository.get('/auth/whoami');

                if (!response) {
                    throw new Error('unauthorized');
                }

                dispatch(UserActions.setUser(response));

                dispatch(UserActions.setWhoAmIRequest({
                    error: null,
                    response: response,
                    loading: false
                }));
            } catch (err) {
                dispatch(UserActions.setUser(null));

                dispatch(UserActions.setWhoAmIRequest({
                    error: err,
                    response: null,
                    loading: false
                }));
            }
        };
    }

    public static checkWhoAmIAndRedirect(to: string): any {
        return async (dispatch: (action: AnyAction) => void) => {
            try {
                dispatch(UserActions.setWhoAmIRequest({
                    error: null,
                    response: null,
                    loading: true
                }));

                const response = await Repository.get('/auth/whoami');

                if (!response) {
                    throw new Error('unauthorized');
                }

                dispatch(UserActions.setUser(response));

                dispatch(UserActions.setWhoAmIRequest({
                    error: null,
                    response: response,
                    loading: false
                }));
                window.location.href = to;
            } catch (err) {
                dispatch(UserActions.setUser(null));
                dispatch(UserActions.setCurrentProduction(null));

                dispatch(UserActions.setWhoAmIRequest({
                    error: err,
                    response: null,
                    loading: false
                }));
            }
        };
    }

}
