import { Field } from '@codebuild/cookie-jar/libs/form/field';
import { Form } from '@codebuild/cookie-jar/libs/form/form';
import { Validator } from '@codebuild/cookie-jar/libs/form/validator/validator';
import { trans } from '@codebuild/cookie-jar/libs/translation/trans';
import { AbstractForm } from '@codebuild/cookie-jar/uikit/components/form/abstract.form';
import { Input } from '@codebuild/cookie-jar/uikit/components/input/input';
import * as React from 'react';
import { Subscribable } from '../../libs/subscribable';
import './gift-ticket-purchase.component.scss';

export class BillingDataForm extends Subscribable<any, any> {
    public state: any = {};

    public form: Form = new Form({
        name: new Field({
            placeholder: '',
            label: trans('form-field-label-billing_name'),
            value: '',
            validators: [
                Validator.REQUIRED(trans('form-validator-required'))
            ]
        }),
        taxNumber: new Field({
            placeholder: '',
            label: trans('form-field-label-tax_number'),
            value: '',
            validators: []
        }),
        country: new Field({
            placeholder: '',
            label: trans('form-field-label-country'),
            value: '',
            validators: [
                Validator.REQUIRED(trans('form-validator-required'))
            ]
        }),
        city: new Field({
            placeholder: '',
            label: trans('form-field-label-city'),
            value: '',
            validators: [
                Validator.REQUIRED(trans('form-validator-required'))
            ]
        }),
        zipCode: new Field({
            placeholder: '',
            label: trans('form-field-label-zip_code'),
            value: '',
            validators: [
                Validator.REQUIRED(trans('form-validator-required'))
            ]
        }),
        address: new Field({
            placeholder: '',
            label: trans('form-field-label-address'),
            value: '',
            validators: [
                Validator.REQUIRED(trans('form-validator-required'))
            ]
        })
    });

    public render() {
        return <AbstractForm className="w-100" form={this.form} onSubmitSuccess={() => console.log(this.form.toJSON())}>
            <div className="row">
                <div className="col-24 col-md-12 mb-4">
                    <Input field={this.form.field('name')} className="variant-primary size-medium asterix" type="text"/>
                </div>
                <div className="col-24 col-md-12 mb-4">
                    <Input field={this.form.field('taxNumber')} className="variant-primary size-medium" type="text"/>
                </div>
                <div className="col-24 col-md-12 mb-4">
                    <Input field={this.form.field('country')} className="variant-primary size-medium asterix" type="email"/>
                </div>
                <div className="col-24 col-md-12 mb-4">
                    <Input field={this.form.field('city')} className="variant-primary size-medium asterix" type="text"/>
                </div>
                <div className="col-24 col-md-6 mb-4">
                    <Input field={this.form.field('zipCode')} className="variant-primary size-medium asterix" type="text"/>
                </div>
                <div className="col-24 col-md-18 mb-4">
                    <Input field={this.form.field('address')} className="variant-primary size-medium asterix" type="text"/>
                </div>
            </div>
        </AbstractForm>;
    }

    public async validate() {
        await this.form.dirty();
        const errors = await this.form.validate();

        return !errors.length;
    }

    public getData() {
        return this.form.toJSON();
    }
}
