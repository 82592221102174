import { trans } from '@codebuild/cookie-jar/libs/translation/trans';
import { TranslationLib } from '@codebuild/cookie-jar/libs/translation/translation.lib';
import { filter, find, get, isEqual, map, sumBy, uniqBy } from 'lodash';
import * as React from 'react';
import { priceFormat } from '../../helpers/price-format';
import { Subscribable } from '../../libs/subscribable';
import { MessageBox } from '../message-box/message-box';
import { TicketCountInput } from './ticket-count-input';
import './ticket-selector.component.scss';

export class TicketSelectorComponent extends Subscribable<any, any> {
    public counterRefs$: any = [];
    public state: any = {
        tickets: [],
        selectedTickets: {},
        limitReached: false,
        redeemedGiftTickets: []

    };

    public componentDidMount() {
        this.setState({ tickets: this.props.tickets });

        if (this.props.limit === 0) {
            this.setState({ limitReached: true });
        }
    }

    public componentDidUpdate(prevProps: Readonly<any>, prevState: Readonly<any>, snapshot?: any) {
        if (!isEqual(this.props.tickets, prevProps.tickets)) {
            this.setState({ tickets: this.props.tickets }, () => {
                this.resetCounters();
            });
        }
    }

    public render() {
        return <div className={'TicketSelectorComponent'}>
            {this.renderTicketSelector()}
        </div>;
    }

    public renderTicketOptions(ticket?: any) {
        return <div className={'display-flex flex-column flex-md-row align-items-md-center align-items-start'}>
            <div className="row w-100">
                <div className="col-12 display-flex align-item-center justify-content-start">
                    <div className={'flex-fill mr-6 mb-md-0 mb-4'}>
                        <span className={'display-flex align-items-center justify-content-start fs-large'}>{this.getTicketTitle(ticket)}</span>
                    </div>
                </div>
                <div className="col-12 display-flex align-items-center justify-content-end">
                    <div className="mr-6 mb-md-0 mb-4">
                        <span className={'fs-large fw-bold'}>{priceFormat(this.props.paymentType === 'ONLINE' ? get(ticket, 'onlinePrice') : get(ticket, 'price'))} Ft</span>
                    </div>
                    <div className="">
                        <TicketCountInput ref={(ref) => this.counterRefs$ = [...this.counterRefs$, ref]} enableIncrease={!this.state.limitReached} ticketType={ticket} quantity={0}
                                          onQuantityChange={(value) => this.handleQuantityChange(value, ticket)}/>
                    </div>
                </div>
            </div>
        </div>;
    }

    public getTicketTitle(ticket) {
        const lang = TranslationLib.getLang();
        const field = `title${lang.toUpperCase()}`;
        const title = (get(ticket, field) && get(ticket, field)?.length) ? get(ticket, field) : get(ticket, 'title');
        return title;
    }

    public renderTicketSelector() {
        return <div className={'TicketSelector display-flex flex-column'}>
            {map(get(this.state, 'tickets'), (value, index) => <div className={'TicketOption px-7 py-6 align-items-center'} key={index}>{this.renderTicketOptions(value)}</div>)}
            {get(this.state, 'limitReached') && this.renderError(trans('ticket-selector-no_more_ticket'))}
        </div>;
    }

    public resetCounters() {
        this.setState({ selectedTickets: {} });
        /*
                map(this.counterRefs$, (ref) => ref.reset());
        */
    }

    public handleQuantityChange(value, ticket) {
        if (this.state.limitReached && this.state.selectedTickets[`${ticket._id}`] <= value) {
            return;
        }
        if (this.props.onTicketSelected) {

            this.setState({
                selectedTickets: {
                    ...this.state.selectedTickets,
                    [`${ticket._id}`]: value
                }
            }, () => {
                this.props.onTicketSelected(this.state.selectedTickets, this.getSummary());
                if (this.getSelectedCount() >= this.props.limit) {
                    this.setState({ limitReached: true });
                    return;
                }
                if (this.getSelectedCount() < this.props.limit) {
                    this.setState({ limitReached: false });
                    return;
                }
            });
        }

    }

    public getSummary() {
        let sum = 0;
        let onlineSum = 0;
        map(this.state.selectedTickets, (value, key) => {
            sum = sum + (find(this.props.tickets, (ticket) => ticket._id === key).price * value);
            onlineSum = onlineSum + (find(this.props.tickets, (ticket) => ticket._id === key).onlinePrice * value);
        });
        let giftTicketSum = sumBy(this.state.redeemedGiftTickets, (t: any) => t.ticketType.price);
        let giftTicketOnlineSum = sumBy(this.state.redeemedGiftTickets, (t: any) => t.ticketType.onlinePrice);

        return {
            sum: sum - giftTicketSum,
            onlineSum: onlineSum - giftTicketOnlineSum
        };
    }

    public renderError(message) {
        if (!message) {
            return;
        }
        return <MessageBox message={message} type={'warning'}/>;
    }

    public getSelectedCount() {
        let count = 0;

        map(this.state.selectedTickets, (t) => (count += t));

        return count;
    }

    public addGiftTicket(ticket: any) {
        console.log(ticket);
        const isRedeemed = !!(filter(this.state.redeemedGiftTickets, (t) => t._id === ticket._id)).length;
        if (isRedeemed) {
            return;
        }
        const redeemedGiftTickets = uniqBy([...this.state.redeemedGiftTickets, ticket], (t) => t._id);
        this.setState({ redeemedGiftTickets: redeemedGiftTickets });
        for (const r of this.counterRefs$) {
            (r as any).addGiftTicket(ticket.ticketType);
        }
    }
}
