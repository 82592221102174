import * as React from 'react';

export default {
    'payment-option-online': 'Online fizetek',
    'payment-option-in_place': 'Helyszínen fizetek',

    'form-validator-required': 'Kötelező mező',
    'form-validator-invalid_email_format': 'Nem megfelelő email formátum',
    'form-field-label-first_name': 'Keresztnév *',
    'form-field-label-last_name': 'Vezetéknév *',
    'form-field-label-email': 'Email *',
    'form-field-label-phone': 'Telefon *',
    'form-field-label-payment': 'Fizetési mód',
    'form-field-label-comment': 'Megjegyzés',
    'form-field-label-billing_name': 'Név/Cégnév *',
    'form-field-label-tax_number': 'Adószám (opcionális)',
    'form-field-label-country': 'Ország *',
    'form-field-label-city': 'Város *',
    'form-field-label-zip_code': 'Irányítószám *',
    'form-field-label-address': 'Cím *',
    'form-accept-agreement': () => (<span>
        Elfogadom az <a target={'_blank'} href={'https://kordafilmpark.hu/adatkezelesi-tajekoztato/'} className={'palette--c-blue-2 text-decoration-none'}> Adatkezelési nyilatkozatot</a> és az <a target={'_blank'}
                                                                                                                                                                                                    href={'https://kordafilmpark.hu/app/uploads/2020/08/aszf_kordafilmpark_200803.pdf'}
                                                                                                                                                                                                    className={'palette--c-blue-2 text-decoration-none'}> Általános szerződési feltételeket</a>
        </span>),
    'button-title-redeem': 'Beváltás',

    'event-type-label-exhibition': 'Kiállítás',
    'event-type-label-event': 'Rendezvény',

    'payment-form-title': 'Számlázási adatok',


    'covid-modal-open-title': 'Covid-19 fontos információk a járványüggyel kapcsolatban',
    'covid-modal-open-button-title': 'Megnyitás',
    'covid-modal-hide-button-title': 'Elrejtés',
    'covid-modal-content-title': 'Covid-19 fontos információk a járványüggyel kapcsolatban',
    'covid-modal-content-description': ()=>(<div>
        <p className={'mb-3'}>Kedves Látogató!</p>
        <p>A Korda Filmpark - visszavonásig - a következő szabályok betartása mellett látogatható:</p>
        <ul>
            <li>A csoportjainkat maximum 25 fővel indítjuk.</li>
            <li>Maszk használata nem kötelező.</li>
            <li>Amennyiben a betegség tüneteit észleli magán, úgy kérjük Önt, hogy a Filmpark látogatását halassza el és erről tájékoztassa kollégáinkat!</li>
        </ul>
        <p>A higiéniai előírásoknak megfelelően fokozottan ügyelünk a kiállítás fertőtlenítésére és tisztítására. A Filmpark területének különböző pontjaira kézfertőtlenítési állomásokat helyeztünk ki, kérjük egészségünk védelmében ezeket használják.</p>
        <p>Fontos kiemelnünk, hogy a belső kiállítás épülete 6 méter belmagasságú, légcserével ellátott térben, a túra idejének egyharmada pedig szabadtéren zajlik.</p>
        <p>Korda Filmpark csapata</p>
        <p><a href="mailto:info@kordafilmpark.hu">info@kordafilmpark.hu</a> \\ <a href="tel:+36 70 455 6815">+36 70 455 6815</a></p>
    </div>)
};
