import * as React from 'react';
import { ReservationComponent } from '../../components/reservation/reservation.component';

export class ReservationScreen extends React.Component<any, {}> {

    public render() {
        return <div className={'container py-8'}>
            <ReservationComponent/>
        </div>;
    }
}
