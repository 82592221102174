import { Field } from '@codebuild/cookie-jar/libs/form/field';
import { Form } from '@codebuild/cookie-jar/libs/form/form';
import { Validator } from '@codebuild/cookie-jar/libs/form/validator/validator';
import { trans } from '@codebuild/cookie-jar/libs/translation/trans';
import { AbstractForm } from '@codebuild/cookie-jar/uikit/components/form/abstract.form';
import { Input } from '@codebuild/cookie-jar/uikit/components/input/input';
import * as React from 'react';
import './call-sheet.component.scss';

export class ParticipantForm extends React.Component<any, any> {
    public form: Form = new Form({
        lastName: new Field({
            placeholder: '',
            label: trans('form-field-label-last_name'),
            value: this.props.ticket.lastName || '',
            validators: [
                Validator.REQUIRED(trans('form-validator-required')),
            ]
        }),
        firstName: new Field({
            placeholder: '',
            label: trans('form-field-label-first_name'),
            value: this.props.ticket.firstName || '',
            validators: [
                Validator.REQUIRED(trans('form-validator-required')),
            ]
        }),
        email: new Field({
            placeholder: '',
            label: trans('form-field-label-email'),
            value: this.props.ticket.email || '',
            validators: [
                Validator.REQUIRED(trans('form-validator-required')),
                Validator.EMAIL('invalid.email.format')
            ]
        })
    });

    public render(): any {
        return <AbstractForm className="w-100" form={this.form} onSubmitSuccess={() => null}>
            <div className={'row mb-4'}>
                <div className="col-8">
                    <Input field={this.form.field('lastName')} className="variant-primary size-medium mx-2"/>
                </div>
                <div className="col-8">
                    <Input field={this.form.field('firstName')} className="variant-primary size-medium mx-2"/>
                </div>
                <div className="col-8">
                    <Input field={this.form.field('email')} className="variant-primary size-medium mx-2"/>
                </div>
            </div>
        </AbstractForm>;
    }

    public async getFormValue() {
        await this.form.dirty();
        const errors = await this.form.validate();
        if (errors.length) {
            return null;
        }
        let data = this.form.toJSON();
        data.ticket = this.props.ticket;
        return data;
    }

    public async isValid() {
        await this.form.dirty();
        const errors = await this.form.validate();
        return !!errors.length;
    }

}
