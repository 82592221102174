import * as React from 'react';

export default {
    'payment-option-online': 'Pay online',
    'payment-option-in_place': 'Pay on spot',

    'form-validator-required': 'Mandatory field',
    'form-validator-invalid_email_format': 'Invalid email format',
    'form-field-label-first_name': 'First name *',
    'form-field-label-last_name': 'Surname *',
    'form-field-label-email': 'Email *',
    'form-field-label-phone': 'Phone number *',
    'form-field-label-payment': 'Payment method',
    'form-field-label-comment': 'Comment',
    'form-field-label-billing_name': 'Name/Company name *',
    'form-field-label-tax_number': 'Tax number (optional)',
    'form-field-label-country': 'Country *',
    'form-field-label-city': 'City *',
    'form-field-label-zip_code': 'Zip code *',
    'form-field-label-address': 'Address *',
    'form-accept-agreement': () => (<span>
        I accept the  <a target={'_blank'} href={'https://kordafilmpark.hu/en/privacy-policy/'} className={'palette--c-blue-2 text-decoration-none'}> Data Management Statement</a> and the <a target={'_blank'}
                                                                                                                                                                                                    href={'https://kordafilmpark.hu/app/uploads/2020/08/aszf_kordafilmpark_200803.pdf'}
                                                                                                                                                                                                    className={'palette--c-blue-2 text-decoration-none'}> General Terms and Conditions</a>
        </span>),
    'event-type-label-exhibition': 'Exhibition',
    'event-type-label-event': 'Event',

    'payment-form-title': 'Billing Information',


    'covid-modal-open-title': 'Important information about Covid-19',
    'covid-modal-open-button-title': 'Open',
    'covid-modal-hide-button-title': 'Close',
    'covid-modal-content-title': 'Important information about Covid-19',
    'covid-modal-content-description': ()=>(<div>
        <p className={'mb-3'}>Dear Visitor!</p>
        <p>The Korda Filmpark can be visited - until further notice - by the following rules:</p>
        <ul>
            <li>We start our groups with a maximum of 25 people.</li>
            <li>The use of a mask is optional.</li>
            <li>If you notice symptoms of the disease, please reschedule your visit to the Filmpark and inform our colleagues about this!</li>
        </ul>
        <p>In accordance with hygiene regulations, we take extra care to disinfect and clean the exhibition. We have placed hand disinfection stations at various points in the Filmpark area, please use them to protect our health.</p>
        <p>It is important to point out that the interior exhibition building is in a 6-meter-high, air-conditioned space, and one-third of the tour takes place outdoors.</p>
        <p>Korda Filmpark team</p>
        <p><a href="mailto:info@kordafilmpark.hu">info@kordafilmpark.hu</a> \\ <a href="tel:+36 70 455 6815">+36 70 455 6815</a></p>
    </div>)
};
