import * as React from 'react';
import { Subscribable } from '../../libs/subscribable';
import './simple.layout.scss';

export class SimpleLayout extends Subscribable<any, any> {

    public render(): any {
        return <div className="SimpleLayout display-flex flex-column Layout position-relative">
            <div className="w-100 flex-fill">
                {this.props.children}
            </div>
        </div>;
    }

    /*  public changeLanguage(lang) {
          TranslationLib.setLang(lang);
          location.reload();
      }*/
}
