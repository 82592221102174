export default {
    'payment-processing-payment-success-title': 'Sikeres vásárlás',
    'payment-processing-payment-success-description': 'Köszönjük, hogy ajándékjegyet vásárolt. A látogatása előtt ne felejtsen el időpontot foglalni!',
    'payment-processing-reservation-success-title': 'Sikeres foglalás',
    'payment-processing-reservation-success-description': 'Köszönjük hogy időpontot foglalt eseményünkre. A foglalás részleteiről emailt küldtünk a foglaláskor megadott email címre.',

    'payment-processing-payment-error-title': 'Sikertelen fizetés',
    'payment-processing-payment-error-retry-button-title': 'Újra próbálom',
    'payment-processing-payment-error-change-method-button-title': 'Inkább helyszínen fizetek',
    'payment-processing-payment-error-description': 'A vásárlás sikertelen volt. Próbáld meg újra.',

    'payment-processing-payment-pending-title': 'Fizetés ellenőrzése folyamatban',
    'payment-processing-payment-pending-description': 'Éppen a fizetés ellenőrzése zajlik. Kérlek várd meg amíg a vásárlás eredménye megjelenik.',

};
