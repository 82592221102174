import eventCalendar from './event-calendar';
import general from './general';
import giftTicket from './gift-ticket';
import paymentProcessing from './payment-processing';
import reservation from './reservation';
import reservationStepper from './reservation-stepper';
import ticketSelector from './ticket-selector';
import callSheet from './call-sheet';

export default {
    ...general,
    ...eventCalendar,
    ...reservation,
    ...ticketSelector,
    ...giftTicket,
    ...reservationStepper,
    ...paymentProcessing,
    ...callSheet,
};
