import { Environment } from '@codebuild/cookie-jar/libs/environment';
import { TranslationLib } from '@codebuild/cookie-jar/libs/translation/translation.lib';
import '@codebuild/cookie-jar/polyfills';
import { ConnectedRouter, connectRouter, routerMiddleware } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import moment from 'moment';
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { Switch } from 'react-router';
import 'react-toastify/dist/ReactToastify.css';
import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import reduxThunk from 'redux-thunk';
import './index.scss';
import { SimpleLayout } from './layouts/simple/simple.layout';
import { BaseRoute } from './libs/base.route';
import { reducers } from './reducers';
import { CallSheetScreen } from './screens/call-sheet/call-sheet.screen';
import { GiftTicketPurchaseScreen } from './screens/gift-ticket-purchase/gift-ticket-purchase.screen';
import { PaymentProcessingScreen } from './screens/payment-processing/payment-processing.screen';
import { ReservationScreen } from './screens/reservation/reservation.screen';

import './scss/index.scss';
import hu from './translation/hu/hu';
import en from './translation/en/en';

const root$ = document.getElementById('application');
const history = createBrowserHistory();
const store = createStore(
    combineReducers({
        ...reducers,
        router: connectRouter(history)
    }),
    compose(
        applyMiddleware(routerMiddleware(history)),
        applyMiddleware(reduxThunk),
    )
);

const RepProvider: any = Provider;
const RepConnectedRouter: any = ConnectedRouter;
const RepSwitch: any = Switch;
const RepBaseRoute: any = BaseRoute;

const renderApp = () => {
    ReactDOM.render(<RepProvider store={store}>
        <RepConnectedRouter history={history}>
            <RepSwitch>
                <RepBaseRoute
                    layout={SimpleLayout}
                    exact={true}
                    path="/"
                    component={ReservationScreen as any}
                />
                <RepBaseRoute
                    layout={SimpleLayout}
                    exact={true}
                    path="/gift"
                    component={GiftTicketPurchaseScreen as any}
                />
                <RepBaseRoute
                    layout={SimpleLayout}
                    exact={true}
                    path="/reservation-payment"
                    component={PaymentProcessingScreen as any}
                />
                <RepBaseRoute
                    layout={SimpleLayout}
                    exact={true}
                    path="/reservation-callsheet"
                    component={CallSheetScreen as any}
                />
            </RepSwitch>
        </RepConnectedRouter>
    </RepProvider>, root$);
};

TranslationLib.setLang(TranslationLib.getLang() || 'hu');
TranslationLib
    .addLangData('hu', hu)
    .addLangData('en', en)
    .initialize();
moment.locale(TranslationLib.getLang());

Environment
    .download()
    .then(() => renderApp())
    .catch(() => alert('Fatal error!'));

