export default {
    'call-sheet-loading-title': 'Feldolgozás folyamatban...',

    'call-sheet-error-title': 'Hiba történt!',
    'call-sheet-error-description': 'Sajnáljuk valami hiba történt. Vedd fel a kapcsolatot a Korda Filmpark munkatársaival.',

    'call-sheet-sent-success-title': 'Meghívók sikeresen kiküldve',
    'call-sheet-sent-success-description': 'A meghívókat elküldtük az Ön által megadott email címekre.',

    'call-sheet-reservation-success-title': 'Sikeres foglalás',
    'call-sheet-reservation-success-description': 'Köszönjük hogy időpontot foglalt eseményünkre. A foglalás részleteiről emailt küldtünk a foglaláskor megadott email címre.',

    'call-sheet-participant-label': 'Résztvevő',
    'call-sheet-participant-form-button-send': 'Küldés',

};
