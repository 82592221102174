import { connect } from '@codebuild/cookie-jar/libs/connect';
import { TranslationLib } from '@codebuild/cookie-jar/libs/translation/translation.lib';
import * as React from 'react';
import { Route, RouteProps } from 'react-router';
import { Subscribable } from './subscribable';

interface BaseRouteProps extends RouteProps {
    layout: any;
}

const mapStateProps = (store: any) => ({});

const mapDispatchProps = (dispatch: any) => ({});

@connect(mapStateProps, mapDispatchProps)
export class BaseRoute extends Subscribable<BaseRouteProps, any> {

    public componentWillMount() {
        const lang: string = new URLSearchParams(location.search).get('lang') || TranslationLib.getLang() || 'hu';
        TranslationLib.setLang(lang);

    }

    public render(): any {
        return React.createElement(
            this.props.layout,
            this.props,
            React.createElement(Route, this.props)
        );
    }
}
