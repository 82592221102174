import * as React from 'react';

export default {
    'reservation-component-title': 'Ticket booking',
    'reservation-component-en-disclaimer': () => (<div className={'mb-4'}>
        <span className={'palette--c-red-2'}>The Film Production Experience Tour is in Hungarian. For English guide, please contact us by email at  <a href="mailto:jegy@kordafilmpark.hu">jegy@kordafilmpark.hu</a></span>
    </div>),
    'reservation-component-subtitle-1': '1. Please choose from the available dates',
    'reservation-component-subtitle-2': '2. Enter how much and which type of tickets you\'d like to reserve',
    'reservation-component-subtitle-3': '3. Personal details and payment method',

    'reservation-component-info-online_payment': 'If you purchase your tickets online, you can get a 10% discount! The offer is only available for online purchase, not for reservation.',
    'reservation-component-info-choose_event': 'Please choose an event first!',
    'reservation-component-info-choose_ticket': 'Please choose a ticket first!',

    'reservation-component-visitor-disclaimer': () => (<div>
        <h5>General information on reservation and visit</h5>

        <p><strong>PRIOR TICKET RESERVATION IS NECESSARY</strong> for individual and group visitors. For reservation, please fill out the form below. The exhibition can be viewed as part of a group tour.</p>
        <p>If fewer than 7 people apply for the given date, we will notify you 2 days before the start of the tour, and we offer another date.</p>
        <p>We inform our dear visitors that the starting time of the tours may change depending on filming and events.</p>

        <p>It is also possible to request <strong>foreign language</strong> or <strong>VIP</strong> group guidance for a special fee of HUF 31,750/group (10% discount in case of online payment). Please <strong>indicate your request in
            advance</strong> in
            the comments section of the reservation or at <a href="mailto:info@kordafilmpark.hu">info@kordafilmpark.hu</a>.
        </p>
        <p>The entire area of the Filmpark is <strong>barrier-free</strong>, so it can be accessed and walked around by wheelchairs and strollers.</p>
        <p>Guests arriving by car or bus are welcome with <strong>free parking</strong>.</p>
        <br />
        <p><strong>More information</strong></p>
        <p>Pictures and sound recordings can be made in Korda Filmpark. By purchasing tickets or participating in events at Korda Filmpark, the Visitor agrees the use of the images and sound recordings made of him/her in the exclusive PR
            and marketing activities of Korda Filmpark. If you do not agree to this, please let us know at our ticket office.</p>
        <p>The exhibition has visual and sound effects suitable for disturbing the peace.</p>
        <p>Recommended minimum age: 12 years.</p>
        <p><small>You can also visit with younger children, but due to insider professional information, the tour is more difficult for them to interpret, so it may not be engaging for them.</small></p>

        <br />
        <p><strong>Information related to the price table:</strong></p>

        <p>* for adult visitors arriving with one or more children aged 6-18</p>
        <p>** for children aged 6-18 arriving with one or more parents, per child</p>
        <p>*** Our discount partners:</p>

        <p>Hungarian Association for Persons with Intellectual Disability (ÉFOÉSZ)</p>
        <p>Hungarian Federation of the Blind and Partially Sighted (MVGYOSZ)</p>
        <p>Hungarian Association of the Deaf and Hard of Hearing (SINOSZ)</p>
        <p>National Federation of Organizations of People with a Physical Disability (MEOSZ)</p>
        <p>Hungarian Autistic Society (AOSZ)</p>
        <p>Down Foundation</p>

        <p>We are NOT able to accept cards issued by the Hungarian State Treasury (MÁK)</p>

    </div>),

    'reservation-component-gift_ticket_code': 'Voucher number',

    'reservation-component-redeemed_gift_tickets': 'Redeemed voucher:',
    'reservation-component-redeemed_coupons': 'Redeemed coupon:',
    'reservation-component-coupon_code-label': 'Coupon number',
    'reservation-component-coupon_discount-label': 'Discount',

    'reservation-component-gift_ticket-error-alreadyUsed': 'Voucher has already been redeemed.',
    'reservation-component-gift_ticket-error-expired': 'Voucher has expired.',
    'reservation-component-gift_ticket-error-alreadyRedeemed': 'Voucher has already been redeemed.',
    'reservation-component-gift_ticket-error-unsupportedTicketType': 'This ticket type is not available for this event.',
    'reservation-component-gift_ticket-error-UnknownGiftTicket': 'Invalid code',
    'reservation-component-gift_ticket-error-default': 'An error occurred',
    'reservation-component-gift_ticket-button-title-redeem': 'Redeem',

    'reservation-component-coupon-error-alreadyUsed': 'Coupon has already been redeemed.',
    'reservation-component-coupon-error-alreadyRedeemed': 'Voucher has already been redeemed.',
    'reservation-component-coupon-error-unsupportedTicketType': 'This ticket type is not available for this event.',
    'reservation-component-coupon-error-UnknownCoupon': 'Invalid code',
    'reservation-component-coupon-error-default': 'An error occurred',
    'reservation-component-coupon-apply-button-title': 'Redeem',
};
