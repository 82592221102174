import { Repository } from '@codebuild/cookie-jar/libs/repository';
import { trans } from '@codebuild/cookie-jar/libs/translation/trans';
import { TranslationLib } from '@codebuild/cookie-jar/libs/translation/translation.lib';
import huLocale from '@fullcalendar/core/locales/hu';
import dayGridPlugin from '@fullcalendar/daygrid';
import FullCalendar from '@fullcalendar/react';
import timeGridPlugin from '@fullcalendar/timegrid';
import { get, map } from 'lodash';
import moment from 'moment';
import * as React from 'react';
import ReactDOM from 'react-dom';
import { Subscribable } from '../../libs/subscribable';
import { Spinner } from '../spinner';
import './event-calendar.component.scss';

export class EventCalendarComponent extends Subscribable<any, any> {
    public static readonly EVENT_TYPES = [
        { value: 'EXHIBITION', title: trans('event-calendar-event-type-label-exhibition') },
        { value: 'EVENT', title: trans('event-calendar-event-type-label-event') }
    ];
    public static readonly EVENT_COLOR_OPTIONS = [
        { value: '#c2cfe6', title: '' },
        { value: '#f7c067', title: '' },
        { value: '#446ff1', title: '' },
        { value: '#56cd80', title: '' },
        { value: '#bf59ff', title: '' },
        { value: '#4cc8c2', title: '' }
    ];
    public $calendarRef: any;
    public state: any = {
        events: [],
        selectedEvent: null,
        loading: false
    };

    public componentDidMount() {
        // this.fetchEvents();
        this.setCalendarView();

        window.onresize = () => {
            this.setCalendarView();
        };

    }

    public render() {
        return <div className={'EventCalendar'}>
            {this.state.loading && <div className={'Loader'}><Spinner size={'large'} theme={'dark'}/></div>}
            <FullCalendar
                timeZone="none"
                rerenderDelay={100}
                ref={(ref) => this.$calendarRef = ref}
                defaultView="timeGridWeek"
                plugins={[timeGridPlugin, dayGridPlugin]}
                height={'auto'}
                header={{
                    left: '',
                    center: 'title',
                    right: 'prev,next,today'
                }}
                locales={[huLocale]}
                locale={TranslationLib.getLang()}
                firstDay={1}
                displayEventEnd={true}
                eventTimeFormat={
                    {
                        hour: '2-digit',
                        minute: '2-digit',
                        meridiem: false

                    }
                }
                columnHeaderFormat={

                    { weekday: 'long', month: 'numeric', day: 'numeric', omitCommas: true }
                }
                titleFormat={
                    {
                        month: 'long', day: 'numeric', year: 'numeric'
                    }
                }
                eventOverlap={true}
                events={map(this.state.events, (event) => event.calendarEvent)}
                eventRender={(data) => this.renderCustomEventCard(data)}
                minTime={'08:00:00'}
                maxTime={'20:00:00'}
                allDaySlot={false}
                slotEventOverlap={false}
                datesRender={(ref) => this.fetchEvents(ref)}
            />
        </div>;
    }

    public async fetchEvents(ref) {

        try {
            this.setState({ loading: true });
            const query = {
                from: get(ref, 'view.currentStart'),
                to: get(ref, 'view.currentEnd'),
                _limit: 10000
            };
            const response = await Repository.get('/event/available', query);

            if (!response) {
                throw new Error('somethingwentwrong');
            }

            this.setState({ events: this.parseEvent(response.items), loading: false }, () => this.$calendarRef.calendar.refetchEvents());
        } catch (err) {
            this.setState({ loading: false });

            console.log(err);
        }
    }

    public renderCustomEventCard(data) {
        const title = this.getEventTypeTitle(get(data, 'event.extendedProps.type'));
        const start = get(data, 'event.start', null) ? moment.utc(get(data, 'event.start')).format('HH:mm') : null;
        const end = get(data, 'event.end', null) ? moment.utc(get(data, 'event.end')).format('HH:mm') : null;

        const numberOfReservations = get(data, 'event.extendedProps.numberOfReservations', 0);
        const max = get(data, 'event.extendedProps.max', 0);
        const textColorClass = this.getTextColorClassToBackground(get(data, 'event.extendedProps.type.color', null));

        const rdom = <div onClick={() => this.onEventClick(data)} className={'w-100 display-flex flex-column CustomEventCard cursor-pointer'}>
            <div className={'display-flex flex-row align-items-start  mb-2'}>
                <div className={'flex-fill display-flex align-items-start flex-wrap'}>
                    <span className={`${textColorClass} fs-small mr-3`}>{start}{end ? `-${end}` : ''} </span>
                </div>
                <span className={`${textColorClass} fs-small`}>{numberOfReservations}{max ? `/${max}` : ''}</span>
            </div>
            <div className={'display-flex justify-content-center'}>
                <span className={`fs-small fw-bold ${textColorClass} mb-2`}>{title}</span>
            </div>
            <div className={'display-flex justify-content-center align-items-center'}>
                {this.renderAvailability(get(data, 'event'))}
            </div>
            <div className="selectIndicator">
                <div className={'CheckIcon'}>
                    <span className={'material-icons palette--c-green-1'}>check</span>
                </div>
            </div>
        </div>;

        $(data.el).on('click', () => this.onEventClick(data));

        if (max <= numberOfReservations) {
            $(data.el).addClass('full');
        }

        if (get(this.state, 'selectedEvent.event.extendedProps._id') === get(data, 'event.extendedProps._id')) {
            $(data.el).addClass('selected');
        }

        ReactDOM.render(rdom, data.el);

    }

    public getEventTypeTitle(eventType) {
        const lang = TranslationLib.getLang();
        const field = `title${lang.toUpperCase()}`;
        const title = (get(eventType, field) && get(eventType, field)?.length) ? get(eventType, field) : get(eventType, 'title');
        return title;
    }

    public getTextColorClassToBackground(color) {
        const colors = map(EventCalendarComponent.EVENT_COLOR_OPTIONS, (option) => option.value);
        if (colors.slice(0, 1).includes(color)) {
            return 'palette--c-neutral-5';
        }

        return 'palette--c-neutral-1';
    }

    public parseEvent(event: any) {
        return map(event, (item) => {
            console.log(item.from);

            return {
                ...item,
                calendarEvent: {
                    start: item.from,
                    end: item.to,
                    title: item.title,
                    backgroundColor: item.type.color,
                    extendedProps: {
                        numberOfReservations: item.numberOfReservations,
                        max: item.max,
                        type: item.type,
                        description: item.description,
                        _id: item._id
                    }
                }
            };
        });
    }

    public onEventClick(data) {
        if (get(data, 'event.extendedProps._id') === get(this.state.selectedEvent, 'event.extendedProps._id')) {
            return;
        }

        const numberOfReservations = get(data, 'event.extendedProps.numberOfReservations', 0);
        const max = get(data, 'event.extendedProps.max', 0);

        if (numberOfReservations >= max) {
            return;
        }
        this.setState({ selectedEvent: data }, () => {
            if (this.props.onEventClick) {
                this.props.onEventClick(data.event);
            }
        });
    }

    public renderAvailability(event) {
        const numberOfReservations = get(event, 'extendedProps.numberOfReservations', 0);
        const max = get(event, 'extendedProps.max', 0);

        if (max - numberOfReservations > 0) {
            return <div>
                <span className={`fs-small ${this.getTextColorClassToBackground(event.backgroundColor)}`}><span
                    className={`fw-bold fs-small ${this.getTextColorClassToBackground(event.backgroundColor)}`}>{`${max - numberOfReservations} `}</span> {trans('event-calendar-event-card-free_slot')}</span>
            </div>;
        }

        return <div>
            <span className={`fs-small ${this.getTextColorClassToBackground(event.backgroundColor)}`}>{trans('event-calendar-event-card-no_free')}</span>
        </div>;
    }

    public setCalendarView() {
        let w = window.outerWidth;

        if (w < 765 && this.$calendarRef.calendar.view.type === 'timeGridWeek') {
            this.$calendarRef.calendar.changeView('timeGridDay');
        }
        if (w >= 765 && this.$calendarRef.calendar.view.type === 'timeGridDay') {
            this.$calendarRef.calendar.changeView('timeGridWeek');
        }
    }
}
