import * as React from 'react';

export default {
    'reservation-component-title': 'Jegyfoglalás',
    'reservation-component-en-disclaimer': ()=>(null),
    'reservation-component-subtitle-1': '1. Válaszd ki melyik eseményre szeretnél jönni.',
    'reservation-component-subtitle-2': '2. Add meg hogy melyik jegytípusból mennyit szeretnél foglalni',
    'reservation-component-subtitle-3': '3. Add meg a kapcsolattartási adataidat és a fizetési módot',

    'reservation-component-info-online_payment': 'Online fizetés esetén a feltüntetett árokból, 10 % kedvezményt adunk! A kedvezmény csak az előre megváltott jegyekre érvényes, a foglalásra nem.',
    'reservation-component-info-choose_event': 'Előbb válassz eseményt!',
    'reservation-component-info-choose_ticket': 'Előbb válassz jegyet!',

    'reservation-component-visitor-disclaimer': () => (<div>
        <h5>Látogatással kapcsolatos információk</h5>

        <p>Mind az egyéni, mind a csoportban érkező látogatóink számára is <strong>SZÜKSÉGES AZ ELŐZETES JEGYFOGLALÁS</strong>, melyet a lent található űrlap kitöltésével tud megtenni. A kiállítás megtekintése csoportos túra keretén
            belül történik. </p>
        <p>Amennyiben kevesebb, mint 7 fő jelentkezik az adott időpontra, a túra indulása előtt 2 nappal írásos értesítést küldünk, amelyben más időpontot ajánlunk fel.</p>
        <p>Értesítjük kedves látogatóinkat, hogy a túrák kezdő időpontja a forgatások és a rendezvények függvényében változhat.</p>
        <p><strong>
            Lehetőség van idegen nyelvű vagy privát csoportvezetésre különdíj ellenében. Igényét kérem, előre jelezze az info@kordafilmpark.hu címen. A felár előre és a belépőjegyeken felül fizetendő.</strong></p>
        <p>A Filmpark egész területe <strong>akadálymentesített</strong>, így kerekesszékkel, babakocsival is megközelíthető és bejárható.</p>
        <p><strong>Ingyenes parkolási</strong> lehetőséggel várjuk az autóval, vagy a busszal érkező vendégeket.</p>

        <br/>
        <p><strong>További információk</strong></p>
        <p>A Korda Filmparkban kép és hangfelvétel készülhet. A Látogató a jegyvásárlással, illetve a Korda Filmpark eseményein való részvétellel hozzájárul, hogy a róla készült kép- és hangfelvételeket a Korda Filmpark kizárólagos pr-
            és marketingtevékenységében felhasználja. Amennyiben ehhez nem járul hozzá, kérjük jelezze jegypénztárunknál.</p>
        <p>A kiállítás a nyugalom megzavarására alkalmas képi és hanghatásokkal rendelkezik.</p>
        <p>Ajánlott alsó korhatár: 12 év.</p>
        <p><small>Kisebb gyerekekkel is látogatható, de a szakmai információk miatt számukra nehezebben értelmezhető és így kevésbé izgalmas a túra.</small></p>

        <br/>
        <p><strong>Ár táblázathoz tartozó információ:</strong></p>

        <p>* egy vagy több 6-18 év közötti gyermekkel érkező felnőtt látogatók részére</p>
        <p>** egy vagy több szülővel érkező 6-18 év közötti gyerekeknek, gyermekenként</p>
        <p>*** Kedvezményes Partnereink:</p>

        <p>Értelmi Fogyatékossággal Élők Országos Érdekvédelmi Szövetsége (ÉFOÉSZ)</p>
        <p>Magyar Vakok és Gyengénlátók Országos Szövetsége (MVGYOSZ)</p>
        <p>Siketek és Nagyothallók Országos Szövetsége (SINOSZ)</p>
        <p>Mozgáskorlátozottak Egyesületeinek Országos Szövetsége (MEOSZ)</p>
        <p>Autisták Országos Szövetsége (AOSZ)</p>
        <p>Down Alapítvány</p>

        <p>NEM áll módunkban elfogadni a Magyar Államkincstár által kibocsátott kártyát (MÁK)</p>

    </div>),

    'reservation-component-gift_ticket_code': 'Ajándékjegy kódja',

    'reservation-component-redeemed_gift_tickets': 'Beváltott ajándékjegyek:',
    'reservation-component-redeemed_coupons': 'Beváltott kupon:',
    'reservation-component-coupon_code-label': 'Kód:',
    'reservation-component-coupon_discount-label': 'Kedvezmény',
    'reservation-component-gift_ticket-error-alreadyUsed': 'A kód már fel lett használva',
    'reservation-component-gift_ticket-error-expired': 'A kód már lejárt',
    'reservation-component-gift_ticket-error-alreadyRedeemed': 'A jegy már be lett váltva',
    'reservation-component-gift_ticket-error-unsupportedTicketType': 'A jegytípus ezen az eseményen nem foglalható',
    'reservation-component-gift_ticket-error-UnknownGiftTicket': 'Érvénytelen kód',
    'reservation-component-gift_ticket-error-default': 'Hiba történt',
    'reservation-component-gift_ticket-button-title-redeem': 'Érvényesítem',

    'reservation-component-coupon-error-alreadyUsed': 'A kód már fel lett használva',
    'reservation-component-coupon-error-alreadyRedeemed': 'A jegy már be lett váltva',
    'reservation-component-coupon-error-unsupportedTicketType': 'A jegytípus ezen az eseményen nem foglalható',
    'reservation-component-coupon-error-UnknownCoupon': 'Érvénytelen kód',
    'reservation-component-coupon-error-default': 'Hiba történt',
    'reservation-component-coupon-apply-button-title': 'Érvényesítem'
};
