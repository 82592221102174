import { Repository } from '@codebuild/cookie-jar/libs/repository';
import { trans } from '@codebuild/cookie-jar/libs/translation/trans';
import { Button } from '@codebuild/cookie-jar/uikit/components/button/button';
import axios from 'axios';
import { get, map } from 'lodash';
import * as React from 'react';
import './call-sheet.component.scss';
import { ParticipantForm } from './participant-form';

export class CallSheetComponent extends React.Component<any, any> {
    public state: any = {
        reservation: null,
        publicToken: null,
        reservationId: null
    };

    public cancelTokenSource = axios.CancelToken.source();
    public participantForms: any[] = [];

    public componentDidMount() {
        const params = (new URLSearchParams(window.location.search));

        this.setState({
            publicToken: params.get('pt'),
            reservationId: params.get('rid')
        }, () => this.getPaymentStatus());
    }

    public render(): any {
        return <div className={'CallSheet palette--bgc-neutral-1 relative'}>
            <div className="mb-8">
                {this.renderForm()}
            </div>
            <div className="row">
                <div className="col-12">
                    <Button className={'variant-secondary size-medium'} onClick={() => this.sendParticipants()}>{trans('call-sheet-participant-form-button-send')}</Button>
                </div>
            </div>
        </div>;
    }

    public async getPaymentStatus() {
        try {
            if (this.cancelTokenSource) {
                this.cancelTokenSource.cancel();
            }

            this.cancelTokenSource = axios.CancelToken.source();

            if (!this.state.publicToken || !this.state.reservationId) {
                throw Error('somethingwentwrong');
            }

            const response = await Repository.get(`/reservation-public/${this.state.reservationId}/${this.state.publicToken}`, {}, { cancelToken: this.cancelTokenSource.token });

            if (!response) {
                throw Error('somethingwentwrong');
            }

            this.setState({ reservation: response });

        } catch (err) {
            console.log(err);
        }
    }

    public renderForm() {
        if (!this.state.reservation) {
            return null;
        }

        return map(get(this.state, 'reservation.tickets', []), (ticket, index) => {
            return <div key={index}>
                <h3>{index + 1}. {trans('call-sheet-participant-label')}</h3>
                <ParticipantForm ref={(ref) => this.participantForms = [...this.participantForms, ref]} key={ticket._id} ticket={ticket}/>
            </div>;
        });
    }

    public async sendParticipants() {
        try {
            let participants: any[] = [];
            let error = false;

            await Promise.all(map(this.participantForms, async (pf) => {
                const fv = await pf.getFormValue();
                const fe = await pf.isValid();
                error = error || fe;
                participants.push(fv);
            }));

            if (error) {
                return;
            }

            await Repository.post(`/reservation-public/${this.state.reservationId}/${this.state.publicToken}/call-sheet`, { participants: participants });
            if (this.props.onSuccess) {
                this.props.onSuccess();
            }
        } catch (err) {
            console.log(err);
        }
    }
}
