import { Repository } from '@codebuild/cookie-jar/libs/repository';
import { trans } from '@codebuild/cookie-jar/libs/translation/trans';
import { Button } from '@codebuild/cookie-jar/uikit/components/button/button';
import axios from 'axios';
import * as React from 'react';
import { CallSheetComponent } from '../../components/callsheet/call-sheet.component';
import { Spinner } from '../../components/spinner';
import './payment-processing.screen.scss';

export class PaymentProcessingScreen extends React.Component<any, {}> {
    public state: any = {
        paymentStatus: null,
        paymentId: null,
        publicToken: null,
        reservationId: null
    };
    public cancelTokenSource = axios.CancelToken.source();

    public componentDidMount() {
        const params = (new URLSearchParams(window.location.search));
        this.setState({
            paymentId: params.get('pid'),
            publicToken: params.get('pt'),
            reservationId: params.get('rid'),
            payableType: params.get('t'),
        }, () => this.getPaymentStatus());
    }

    public render() {
        return <div className={'container py-8'}>
            {this.renderStatus()}
        </div>;
    }

    public async getPaymentStatus() {
        try {
            if (this.cancelTokenSource) {
                this.cancelTokenSource.cancel();
            }
            this.cancelTokenSource = axios.CancelToken.source();

            if (!this.state.paymentId || !this.state.publicToken || !this.state.reservationId || !this.state.payableType) {
                throw Error('somethingwentwrong');
            }
            let response: any = null;

            if (this.state.payableType === 'reservation') {
                response = await Repository.get(`/reservation-public/${this.state.reservationId}/${this.state.publicToken}/payment-status/${this.state.paymentId}`, {}, { cancelToken: this.cancelTokenSource.token });
            }

            if (this.state.payableType === 'gift-ticket-purchase') {
                response = await Repository.get(`/gift-ticket-purchase/${this.state.reservationId}/${this.state.publicToken}/payment-status/${this.state.paymentId}`, {}, { cancelToken: this.cancelTokenSource.token });
            }

            if (!response) {
                throw Error('somethingwentwrong');
            }

            this.setState({ paymentStatus: response.status });

        } catch (err) {
            console.log(err);
        }
    }

    public async changePayment() {
        try {
            if (!this.state.reservationId || !this.state.publicToken) {
                throw Error('somethingwentwrong');
            }

            const response = await Repository.put(`/reservation-public/${this.state.reservationId}/${this.state.publicToken}/payment-type`, { paymentType: 'IN_PLACE' });
            if (!response) {
                throw Error('somethingwentwrong');
            }

            this.setState({ paymentStatus: 'SUCCESS' });

        } catch (err) {
            console.log(err);
        }
    }

    public async restartOnlinePayment() {
        try {
            if (!this.state.reservationId || !this.state.publicToken) {
                throw Error('somethingwentwrong');
            }

            const response = await Repository.put(`/reservation-public/${this.state.reservationId}/${this.state.publicToken}/payment-type`, { paymentType: 'ONLINE' });
            if (!response) {
                throw Error('somethingwentwrong');
            }
            if (response.paymentType === 'ONLINE' && response.gatewayCredentials && response.gatewayCredentials.popupWindowUrl) {

                location.href = response.gatewayCredentials.popupWindowUrl;
            }

        } catch (err) {
            console.log(err);
        }
    }

    public renderStatus() {
        if (this.state.paymentStatus === 'SUCCESS') {
            return this.renderSuccess();
        }
        if (['FAILED', 'UNKNOWN', 'TIMEOUT'].includes(this.state.paymentStatus)) {
            return this.renderFailed();
        }
        setTimeout(() => this.getPaymentStatus(), 1000);
        return this.renderPending();
    }

    public renderSuccess() {
        if (this.state.payableType === 'gift-ticket-purchase') {
            return <div>
                <div className={'PaymentProcessing palette--bgc-neutral-1 elevation-1 px-8 py-8'}>
                    <div className="mb-8">
                        <h2 className={'mb-8'}><span className={'material-icons SuccessIcon'}>check</span> {trans('payment-processing-payment-success-title')}</h2>
                        <span className={'palette--c-neutral-5 fs-medium'}>{trans('payment-processing-payment-success-description')}</span>
                    </div>
                </div>
            </div>;
        }

        return <div>
            <div className={'PaymentProcessing palette--bgc-neutral-1 elevation-1 px-8 py-8'}>
                <div className="mb-8">
                    <h2 className={'mb-8'}><span className={'material-icons SuccessIcon'}>check</span>{trans('payment-processing-reservation-success-title')}</h2>
                    <span className={'palette--c-neutral-5 fs-medium'}>{trans('payment-processing-reservation-success-description')}</span>
                </div>
                <CallSheetComponent onSuccess={() => this.setState({ success: true })}/>
            </div>
        </div>;
    }

    public renderFailed() {
        return <div>
            <div className={'PaymentProcessing palette--bgc-neutral-1 elevation-1 px-8 py-8'}>
                <h2 className={'mb-8'}><span className={'material-icons ErrorIcon'}>priority_high</span>{trans('payment-processing-payment-error-title')}</h2>
                <span className={'palette--c-neutral-5 fs-medium mb-8'}>{trans('payment-processing-payment-error-description')}</span>
                <Button className={'variant-secondary size-medium'} onClick={() => this.restartOnlinePayment()}>{trans('payment-processing-payment-error-retry-button-title')}</Button>
                <br/>
                {
                    this.state.payableType === 'reservation' &&
                    <span className={'palette--c-blue-2 cursor-pointer'} onClick={() => this.changePayment()}>{trans('payment-processing-payment-error-change-method-button-title')}</span>
                }
            </div>
        </div>;
    }

    public renderPending() {
        return <div>
            <div className={'PaymentProcessing palette--bgc-neutral-1 elevation-1 px-8 py-8'}>
                <div className="display-flex justify-content-center align-items-center mb-8">
                    <Spinner theme={'dark'} size={'large'}/>
                </div>
                <div className="display-flex flex-column justify-content-center align-items-center">
                    <h2 className={'mb-8'}>{trans('payment-processing-payment-pending-title')}</h2>
                    <span className={'palette--c-neutral-5 fs-medium'}>{trans('payment-processing-payment-pending-description')}</span>
                </div>
            </div>
        </div>;
    }
}
