import { Repository } from '@codebuild/cookie-jar/libs/repository';
import { trans } from '@codebuild/cookie-jar/libs/translation/trans';
import qs from 'query-string';
import * as React from 'react';
import { CallSheetComponent } from '../../components/callsheet/call-sheet.component';
import './call-sheet.screen.scss';

export class CallSheetScreen extends React.Component<any, {}> {
    public state: any = {
        loading: false,
        reservation: null,
        error: false,
        success: false
    };

    public componentDidMount(): void {
        this.fetchReservation();
    }

    public getReservationId() {
        const parsed = qs.parseUrl(window.location.href);

        return parsed.query.rid;
    }

    public getPublicToken() {
        const parsed = qs.parseUrl(window.location.href);

        return parsed.query.pt;
    }

    public async fetchReservation() {
        try {
            this.setState({
                loading: true,
                error: false
            });

            const response = await Repository.get(`/reservation-public/${this.getReservationId()}/${this.getPublicToken()}`);

            this.setState({
                loading: false,
                reservation: response
            });

        } catch (e) {
            this.setState({
                loading: false,
                error: true
            });
        }
    }

    public render() {

        if (this.state.loading) {
            return <div className={'container py-8 CallSheetScreen'}>
                <div className={'CallSheetScreen palette--bgc-neutral-1 elevation-1 px-8 py-8'}>
                    <div className="">
                        <h2 className={''}><span className={'material-icons LoadingIcon spinner'}>autorenew</span> {trans('call-sheet-loading-title')} </h2>
                    </div>
                </div>
            </div>;
        }

        if (this.state.error) {
            return <div className={'container py-8'}>
                <div className={'CallSheetScreen palette--bgc-neutral-1 elevation-1 px-8 py-8'}>
                    <div className="">
                        <h2 className={'mb-8'}><span className={'material-icons ErrorIcon mr-4'}>error</span> {trans('call-sheet-error-title')}</h2>
                        <span className={'palette--c-neutral-5 fs-medium'}>{trans('call-sheet-error-description')}</span>
                    </div>
                </div>
            </div>;
        }

        if (this.state.success) {
            return <div className={'container py-8'}>
                <div className={'CallSheetScreen palette--bgc-neutral-1 elevation-1 px-8 py-8'}>
                    <div className="">
                        <h2 className={'mb-8'}><span className={'material-icons SuccessIcon mr-4'}>check</span> {trans('call-sheet-sent-success-title')}</h2>
                        <span className={'palette--c-neutral-5 fs-medium'}>{trans('call-sheet-sent-success-description')}</span>
                    </div>
                </div>
            </div>;
        }

        return <div className={'container py-8'}>
            <div className={'CallSheetScreen palette--bgc-neutral-1 elevation-1 px-8 py-8'}>
                <div className="">
                    <h2 className={'mb-8'}><span className={'material-icons SuccessIcon mr-4'}>check</span>{trans('call-sheet-reservation-success-title')}</h2>
                    <span className={'palette--c-neutral-5 fs-medium'}>{trans('call-sheet-reservation-success-description')}</span>
                </div>
                <CallSheetComponent onSuccess={() => this.setState({ success: true })}/>
            </div>
        </div>;
    }
}
