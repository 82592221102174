import { Field } from '@codebuild/cookie-jar/libs/form/field';
import { Form } from '@codebuild/cookie-jar/libs/form/form';
import { Validator } from '@codebuild/cookie-jar/libs/form/validator/validator';
import { Repository } from '@codebuild/cookie-jar/libs/repository';
import { trans } from '@codebuild/cookie-jar/libs/translation/trans';
import { AbstractForm } from '@codebuild/cookie-jar/uikit/components/form/abstract.form';
import { Checkbox } from '@codebuild/cookie-jar/uikit/components/input/checkbox';
import { Input } from '@codebuild/cookie-jar/uikit/components/input/input';
import { Select } from '@codebuild/cookie-jar/uikit/components/input/select';
import { filter, get, map } from 'lodash';
import * as React from 'react';
import { toast } from 'react-toastify';
import { HandleToasts } from '../../handle.toasts';
import { getErrorMessage } from '../../helpers/errors';
import { Subscribable } from '../../libs/subscribable';
import { MessageBox } from '../message-box/message-box';
import { ReservationStepper } from '../reservation-stepper/reservation-stepper';
import { Spinner } from '../spinner';
import { TicketSelectorComponent } from '../ticket-selector/ticket-selector.component';
import { BillingDataForm } from './billing-data.form';
import './gift-ticket-purchase.component.scss';

export class GiftTicketPurchaseComponent extends Subscribable<any, any> {
    public paymentOptions = [
        { type: 'ONLINE', title: trans('payment-option-online') },
    ];
    public billingDataFormRef;
    public state: any = {
        selectedTickets: [],
        errors: [],
        agreement: true,
        reservationSuccess: false,
        enabledTicketTypes: [],
        isLoading: false,
        paymentType: 'ONLINE'
    };

    public form: Form = new Form({
        firstName: new Field({
            placeholder: '',
            label: trans('form-field-label-first_name'),
            value: '',
            validators: [
                Validator.REQUIRED(trans('form-validator-required'))
            ]
        }),
        lastName: new Field({
            placeholder: '',
            label: trans('form-field-label-last_name'),
            value: '',
            validators: [
                Validator.REQUIRED(trans('form-validator-required'))
            ]
        }),
        email: new Field({
            placeholder: '',
            label: trans('form-field-label-email'),
            value: '',
            validators: [
                Validator.REQUIRED(trans('form-validator-required')),
                Validator.EMAIL(trans('form-validator-invalid_email_format'))
            ]
        }),
        phone: new Field({
            placeholder: '',
            label: trans('form-field-label-phone'),
            value: '',
            validators: [
                Validator.REQUIRED(trans('form-validator-required'))
            ]
        }),
        payment: new Field({
            label: trans('form-field-label-payment'),
            value: this.paymentOptions[0],
            options: this.paymentOptions,
            validators: [Validator.REQUIRED(trans('form-validator-required'))]
        }),
        comment: new Field({
            placeholder: '',
            label: trans('form-field-label-comment'),
            value: '',
            validators: []
        }),
        agreement: new Field({
            label: '',
            value: '',
            validators: [
                Validator.REQUIRED(trans('form-validator-required'))
            ]
        })

    });

    public componentDidMount() {

        this.subscriptions$.push(
            this.form.field('agreement').dirty$.subscribe((value) => {
                this.setState({ agreement: (this.form.field('agreement').dirty && !this.form.field('agreement').errors.length) || !this.form.field('agreement').dirty }, () => console.log(this.state.agreement));

            }),
            this.form.field('agreement').value$.subscribe((value) => {
                this.setState({ agreement: (this.form.field('agreement').dirty && !this.form.field('agreement').errors.length) || !this.form.field('agreement').dirty }, () => console.log(this.state.agreement));
            }),
            this.form.field('payment').value$.subscribe((v: any) => this.setState({ paymentType: v.type }))
        );
        this.fetchGiftableTickets();
    }

    public async fetchGiftableTickets() {
        try {
            const response = await Repository.get('/ticket-type', { gift: 1 });
            if (!response || !response.items) {
                throw new Error('somethingwentwrong');
            }
            this.setState({ enabledTicketTypes: response.items });

        } catch (e) {
            HandleToasts.error(e);
        }
    }

    public render() {
        return <div className={'GiftTicketPurchaseComponent palette--bgc-neutral-1 elevation-1 relative px-8 py-8'}>
            {this.state.isLoading && <div className={'Loader'}><Spinner size={'large'} theme={'dark'}/></div>}
            <h1 className={'mb-8'}>{trans('gift-ticket-purchase-title')}</h1>
            <div className="row mb-8" id={'TicketSelectorForm'}>
                <div className="col-lg-12 col-24">
                    <div className={'row'}>
                        <div className="col-24 mb-6">
                            <h4>{trans('gift-ticket-purchase-subtitle-1')}</h4>

                            <TicketSelectorComponent paymentType={this.state.paymentType} limit={this.getAvailableTicketCount()} tickets={get(this.state, 'enabledTicketTypes')}
                                                     onTicketSelected={(value, sum) => this.handleTicketSelected(value, sum)}/>
                            {this.renderTicketDependencyErrors()}
                        </div>
                        <div className="col-24 mb-6"/>
                        <div className="col-24 mb-6">
                            <h4>{trans('gift-ticket-purchase-subtitle-2')}</h4>
                            {!!get(this.state, 'selectedTickets', []).length && this.renderForm()}
                            {!get(this.state, 'selectedTickets', []).length && <MessageBox type={'info'} message={trans('gift-ticket-purchase-info-choose_ticket')}/>}
                        </div>
                    </div>
                </div>
                <div className="col-lg-12 col-24">
                    <MessageBox type={'info'} message={this.renderInfoContent()}/>
                </div>
            </div>
            <div className="row">
                {map(this.state.errors, (error) => <MessageBox type={'error'} message={getErrorMessage(error)}/>)}
            </div>
            <div className={'ReservationFooter'}>
                <ReservationStepper isGiftPurchase={true} onSubmit={() => this.handleSubmit()} step={this.getCurrentStep()} price={this.state.sumPrice}/>
            </div>
        </div>;
    }

    private async getPayload() {
        await this.setState({ errors: [] },);
        if (!this.state.selectedTickets.length) {
            this.setState({ errors: [...this.state.errors, { type: 'MissingTicket' }] });
        }

        if (this.state.errors.length) {
            return;
        }

        await this.form.dirty();
        const errors = await this.form.validate();
        let billingData = null;
        if (get(this.state, 'paymentType') === 'ONLINE') {
            const billingValidation = await this.billingDataFormRef.validate();
            if (!billingValidation) {
                return;
            }

            billingData = this.billingDataFormRef.getData();
        }

        if (errors.length) {
            return;
        }

        return {
            ...this.form.toJSON(),
            ticketTypes: this.state.selectedTickets,
            billingData: billingData
        };
    }

    private async handleSubmit() {
        try {
            this.setState({ isLoading: true });
            const payload = await this.getPayload();
            if (!payload) {
                return;
            }

            const response = await Repository.post('/gift-ticket-purchase', payload);

            if (!response) {
                throw Error('SomethingWentWrong');
            }

            if (get(response, 'payment.type') === 'ONLINE' && response.gatewayCredentials && response.gatewayCredentials.popupWindowUrl && response.instance) {
                window.location.href = response.gatewayCredentials.popupWindowUrl;
            } else {
                throw new Error('UnknownResult');
            }

        } catch (err) {
            this.setState({ reservationSuccess: false, isLoading: false });
            map(get(err, 'response.data.payload.errors') || [], (e) => this.setState({ errors: [...this.state.errors, e] }));
            console.log(this.state.errors);
        } finally {
            toast.error('Nem sikerült végrehajtani a műveletet');
            this.setState({ isLoading: false });
        }
    }

    public renderTicketDependencyErrors() {
        const dependencyErrors: any[] = filter(this.state.errors, (e) => e.type === 'MissingTicketTypeDependency');
        if (!dependencyErrors.length) {
            return;
        }
        return <div className={'display-flex flex-column w-100 p-box border-1 palette--bc-red-2 palette--bgc-red-1 border-radius-1'}>
            {dependencyErrors.map((e) => {
                return <div className={'display-flex flex-column mb-6'}>
                    <span className={'mb-4'}>A <strong>{get(e, 'payload.ticketType.title')}</strong> jegyhez az  alábbi jegyek szükségesek:</span>
                    {get(e, 'payload.dependencies', []).map((d) => {
                        return <span className={'pl-6'}>- {get(d, 'title')}</span>;
                    })}
                </div>;
            })}
        </div>;
    }

    public getCurrentStep() {
        let step = 1;
        if (!!this.state.selectedTickets.length) {
            step = step + 1;
        }

        return step;
    }

    private handleTicketSelected(tickets, sum) {
        let selectedTickets: any[] = [];
        map(tickets, (count, key) => {
            selectedTickets = [...selectedTickets, ...Array(count).fill(key)];
        });
        this.setState({ selectedTickets: selectedTickets, sumPrice: sum?.onlineSum });
    }

    private renderForm() {
        return <AbstractForm className="w-100" form={this.form} onSubmitSuccess={() => this.props.onSuccess(this.form.toJSON())}>
            <div className="row">
                <div className="col-24 col-md-12 mb-4">
                    <Input field={this.form.field('lastName')} className="variant-primary size-medium" type="text"/>
                </div>
                <div className="col-24 col-md-12 mb-4">
                    <Input field={this.form.field('firstName')} className="variant-primary size-medium" type="text"/>
                </div>
                <div className="col-24 col-md-12 mb-4">
                    <Input field={this.form.field('email')} className="variant-primary size-medium" type="email"/>
                </div>
                <div className="col-24 col-md-12 mb-4">
                    <Input field={this.form.field('phone')} className="variant-primary size-medium" type="text"/>
                </div>
            </div>
            <div className="row">
                <div className="col-24 col-md-12 mb-4">
                    <Select
                        valueProperty="type"
                        disabled={true}
                        field={this.form.field('payment')}
                        className="size-medium variant-primary mb-2"
                    />
                </div>
            </div>


            {get(this.state.payment, 'type') === 'ONLINE' && <div className="row">
                <div className="col-24 col-md-12 mb-4">
                    <img src="/assets/barion-card-strip-intl_1200px.png" width="100%" alt=""/>
                </div>
            </div>}

            <div className="row">
                <div className="col-24 mb-4">
                    <Input
                        element={'textarea'}
                        rows={6}
                        field={this.form.field('comment')}
                        className="variant-primary size-medium"
                        type="text"
                    />
                </div>
            </div>
            {get(this.state, 'paymentType') === 'ONLINE' && <div className={'mb-4'}>
                <h5>{trans('payment-form-title')}</h5>
                <BillingDataForm ref={(ref) => this.billingDataFormRef = ref}/>
            </div>}
            <div className="row">
                <div className="col-24 mb-4">
                    <div className="display-flex flex-row justify-content-start align-items-center">
                        <Checkbox
                            disabled={false}
                            field={this.form.field('agreement')}
                            className="size-small variant-primary mr-4"
                            inputClassName={'display-none'}
                        />
                        <div>
                            {trans('form-accept-agreement')}
                        </div>
                    </div>
                    {!this.state.agreement && <span className={'palette--c-red-2'}>{trans('form-validator-required')}</span>}
                </div>
            </div>
        </AbstractForm>;
    }

    public renderInfoContent() {
        return trans('gift-ticket-purchase-visitor-disclaimer');
    }

    private getAvailableTicketCount() {
        return 1000;
    }
}
