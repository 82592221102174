import { get } from 'lodash';

export function getErrorMessage(error) {
    switch (error.type) {
        case 'MissingTicket':
            return 'Nincs jegy kiválasztva!';
        case 'EventMaxAttendantReached':
            return `Nincs ${get(error, 'payload.attendants')} szabad hely az eseményen!`;
        default:
            return 'Hiba történt!';
    }
}
