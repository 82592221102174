import { trans } from '@codebuild/cookie-jar/libs/translation/trans';
import { Button } from '@codebuild/cookie-jar/uikit/components/button/button';
import { get } from 'lodash';
import * as React from 'react';
import { priceFormat } from '../../helpers/price-format';
import './reservation-stepper.scss';

export class ReservationStepper extends React.Component<any, any> {

    public state: any = {
        isGiftPurchase: this.props.isGiftPurchase || false,
        currentStep: 0,
        currentPrice: Math.floor((this.props.price || 0) * (1 - get(this.props, 'discount', 0)))
    };

    public componentDidUpdate(prevProps: Readonly<any>, prevState: Readonly<any>, snapshot?: any) {
        if (this.props.step !== prevProps.step) {
            this.setState({ currentStep: this.props.step });
        }
        if (this.props.price !== prevProps.price || this.props.discount !== prevProps.discount) {
            console.log('STEPPER', this.props.price);
            console.log('STEPPER', this.props.discount);
            const currentPrice = Math.floor((this.props.price || 0) * (1 - get(this.props, 'discount', 0)));
            this.setState({ currentPrice: currentPrice || 0 });
        }
    }

    public render(): any {
        return <div className={'ReservationStepper elevation-3'}>
            <div className="row">
                <div className="col-md-12 col-24 display-md-flex display-none">
                    <div className={'Steps'}>
                        {!this.state.isGiftPurchase && <div className={`Step ${this.isCompletedStep(0) ? 'active' : ''} ${this.isCurrentStep(0) ? 'next' : ''}`}>
                            <div className={'mb-3 display-flex flex-row align-items-center'}>
                                <div className={'StepCircle'}/>
                                <div className={'StepLine flex-fill'}/>
                            </div>
                            <span className={'pr-8 palette--c-neutral-5'}>{trans('reservation-stepper-step_1')}</span>
                        </div>}
                        <div className={`Step ${this.isCompletedStep(1) ? 'active' : ''} ${this.isCurrentStep(1) ? 'next' : ''}`}>
                            <div className={'mb-3 display-flex flex-row align-items-center'}>
                                <div className={'StepCircle'}/>
                                <div className={'StepLine flex-fill'}/>
                            </div>
                            <span className={'pr-8 palette--c-neutral-5'}>{trans('reservation-stepper-step_2')}</span>

                        </div>
                        <div className={`Step ${this.isCompletedStep(2) ? 'active' : ''} ${this.isCurrentStep(2) ? 'next' : ''}`}>
                            <div className={'mb-3 display-flex flex-row align-items-center'}>
                                <div className={'StepCircle'}/>
                            </div>
                            <span className={'pr-8 palette--c-neutral-5'}>{trans('reservation-stepper-step_3')}</span>
                        </div>
                    </div>
                </div>
                <div className="col-md-12 col-24">
                    <div className="row">
                        <div className={'col-12'}>
                            <span>{trans('reservation-stepper-total-label')}</span>
                            <br/>
                            <h3>{priceFormat(get(this.state, 'currentPrice', 0))} Ft</h3>
                        </div>
                        <div className={'col-12'}>
                            <Button onClick={() => this.props.onSubmit()} className={'variant-green size-large'}>{this.state.isGiftPurchase ? trans('reservation-stepper-purchase') : trans('reservation-stepper-submit')}</Button>
                        </div>
                    </div>
                </div>
            </div>
        </div>;
    }

    public isCompletedStep(step) {
        return get(this.state, 'currentStep', 0) > step;
    }

    public isCurrentStep(step) {
        return get(this.state, 'currentStep', 0) === step;
    }
}
