export default {
    'payment-processing-payment-success-title': 'Successful purchase',
    'payment-processing-payment-success-description': 'Thank you for purchasing a gift ticket. Please remember to make a reservation before your visit!',
    'payment-processing-reservation-success-title': 'Successful reservation',
    'payment-processing-reservation-success-description': 'Thank you for reserving a spot at our event. We have sent an email to the address you provided with the reservation details.',

    'payment-processing-payment-error-title': 'Payment failed',
    'payment-processing-payment-error-retry-button-title': 'Try again',
    'payment-processing-payment-error-change-method-button-title': 'Pay on site instead',
    'payment-processing-payment-error-description': 'The purchase was unsuccessful. Please try again.',

    'payment-processing-payment-pending-title': 'Payment is being verified',
    'payment-processing-payment-pending-description': 'We are currently verifying your payment. Please wait for the purchase result to appear.',
};
