export default {
    'call-sheet-loading-title': 'Processing...',

    'call-sheet-error-title': 'Error!',
    'call-sheet-error-description': 'Sorry, something went wrong. Please contact the staff of Korda Filmpark.',

    'call-sheet-sent-success-title': 'Invitations sent successfully',
    'call-sheet-sent-success-description': 'We have sent the invitations to the email addresses you provided.',

    'call-sheet-reservation-success-title': 'Successful reservation',
    'call-sheet-reservation-success-description': 'Thank you for reserving a spot at our event. We have sent an email to the address you provided with the reservation details.',

    'call-sheet-participant-label': 'Participant',
    'call-sheet-participant-form-button-send': 'Send',
};

