import { Field } from '@codebuild/cookie-jar/libs/form/field';
import { Form } from '@codebuild/cookie-jar/libs/form/form';
import { Button } from '@codebuild/cookie-jar/uikit/components/button/button';
import { AbstractForm } from '@codebuild/cookie-jar/uikit/components/form/abstract.form';
import * as React from 'react';
import { Subscribable } from '../../libs/subscribable';
import './ticket-selector.component.scss';

export class TicketCountInput extends Subscribable<any, any> {
    public state: any = {
        giftTicketCount: 0
    };
    public form: Form = new Form({
        quantity: new Field({
            value: 0
        })
    });

    public componentDidMount() {
        this.form.field('quantity').value$.subscribe((value) => {
            if (this.props.onQuantityChange) {
                return this.props.onQuantityChange(parseInt(this.form.field('quantity').getValue() || 0));
            }
        });
    }

    public render() {
        return <div className={'TicketCountInput display-flex flex-row'}>
            <AbstractForm className="w-100" form={this.form} onSubmitSuccess={() => null}>
                <div className={'display-flex flex-row'}>
                    <Button className={'variant-primary size-medium'} onClick={() => this.decreaseQuantity()}><span className={'material-icons fs-large palette--c-neutral-6'}>remove</span></Button>
                    {/*<Input field={this.form.field('quantity')} min={0} type={'number'} className="TicketCountInputField variant-primary size-medium mx-2"/>*/}
                    <div className={'TicketCountText py-2 px-4 display-flex justify-content-center align-items-center'}>
                        <span className={'fs-large palette--c-neutral-5'}>{this.form.field('quantity').getValue()}</span>
                    </div>
                    <Button className={'variant-primary size-medium'} onClick={() => this.increaseQuantity()}><span className={'material-icons fs-large palette--c-neutral-6'}>add</span></Button>
                </div>
            </AbstractForm>
        </div>;
    }

    public reset() {
        this.form.field('quantity').setValue(0);
    }

    public addGiftTicket(type: any) {
        if (type._id === this.props.ticketType._id) {
            const giftTicketCount = this.state.giftTicketCount;
            this.setState({ giftTicketCount: giftTicketCount + 1 });
            this.increaseQuantity();
        }
    }

    public removeGiftTicket(type: any) {
        if (type._id === this.props.ticketType._id) {
            this.decreaseQuantity();
        }
    }

    private increaseQuantity() {
        if (!this.props.enableIncrease) {
            return;
        }
        this.form.field('quantity').setValue((parseInt(this.form.field('quantity').getValue()) + 1));
    }

    private decreaseQuantity() {
        if (this.form.field('quantity').getValue() <= this.state.giftTicketCount) {
            return;
        }
        this.form.field('quantity').setValue((parseInt(this.form.field('quantity').getValue()) - 1));
    }
}
